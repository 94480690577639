@use "../../../premmio/public/styles/breakpoints";
@use "../../../premmio/private/styles/partials/neumorph";

section {
  article {
    position: relative;
    z-index: 2;
    background: transparent;
  }

  aside {
    position: relative;
    z-index: 1;
    padding: 0 !important;
    overflow: hidden;

    svg {
      width: 100%;
      height: auto;
      position: relative;
      z-index: 2;
      scale: 1.1;

      path {
        stroke-dasharray: 200rem;

        @for $i from 1 through 7 {
          &:nth-of-type(#{$i}) {
            stroke-dashoffset: calc(-200rem - var(--pixels#{$i}));
          }
        }
      }

      &.squiggles {
        --pixels1: calc(var(--parallaxPx) * 2.4843); /* 1.911 * 1.3 */
        --pixels2: calc(var(--parallaxPx) * 2.63); /* 2.023 * 1.3 */
        --pixels3: calc(var(--parallaxPx) * 2.3296); /* 1.792 * 1.3 */
        --pixels4: calc(var(--parallaxPx) * 2.7664); /* 2.128 * 1.3 */
        --pixels5: calc(var(--parallaxPx) * 2.5298); /* 1.946 * 1.3 */
        --pixels6: calc(var(--parallaxPx) * 2.6845); /* 2.065 * 1.3 */
        --pixels7: calc(var(--parallaxPx) * 2.4297); /* 1.869 * 1.3 */
      }

      &.spiral {
        --pixels1: calc(var(--parallaxPx) * 1.05);
      }
    }

    img {
      --parallaxPx: 0px;
      --pixels: calc(var(--parallaxPx) * 0.1);
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, calc(-50% + var(--pixels)));
      height: 150%;
      width: auto;
      object-fit: cover;
    }
  }
}

// #howItWorks {
//   article {
//     padding: 3rem;
//     box-sizing: border-box;
//     border-radius: 25px;
//     max-width: breakpoints.$lg;
//     @include neumorph.inset;
//   }

//   h2 {
//     text-align: center;
//   }

//   #steps {
//     margin: 5rem 0;

//     .step {
//       box-sizing: border-box;
//       padding: 1rem;

//       h3 {
//         text-align: center;
//       }

//       + .step {
//         margin-top: 0.5rem;
//       }
//     }
//   }

//   .btn-container {
//     margin-top: 2rem;
//   }
// }

// #screenshot {
//   width: 100%;
//   max-width: breakpoints.$lg;
//   margin: 2rem auto -10rem auto;
//   border-radius: 25px;
//   box-shadow: var(--box-shadow);
// }

// #features-list {
//   flex: 0 0 100%;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   position: relative;

//   svg.premmio {
//     width: 20rem;
//     height: 20rem;
//     position: absolute;
//     top: 0;
//     left: 50%;
//     transform: translateX(-50%);
//     z-index: 1;
//     opacity: 0.25;

//     * {
//       stroke: var(--primary-500);
//     }
//   }

//   .slider {
//     --slider-button-color: var(--dark-900);
//     position: relative;
//     z-index: 2;

//     height: 25rem;

//     .slide {
//       max-width: 20rem;
//       justify-content: flex-start;
//     }
//   }

//   .feature {
//     padding: 2rem;
//     box-sizing: border-box;

//     h3 {
//       font-size: var(--fs5);
//     }

//     .icon {
//       width: 100%;
//       fill: var(--dark-900);
//       max-width: 11rem;
//       margin: 0 auto;
//       display: block;
//     }

//     p {
//       em {
//         color: var(--primary-500);
//       }
//     }
//   }
// }

// #features {
//   padding-left: 0;
//   padding-right: 0;

//   h2 {
//     text-align: center;
//   }

//   aside {
//     padding: 0;
//   }
// }

@media (width > breakpoints.$sm) {
  // #features-list {
  //   .feature {
  //     flex: 0 0 50%;
  //   }
  // }
}

@media (width > breakpoints.$md) {
  // #howItWorks {
  //   #steps {
  //     display: flex;
  //     gap: 2rem;

  //     .step {
  //       flex: 0 0 calc(33.3333% - 1rem);

  //       + .step {
  //         margin-top: 0;
  //       }
  //     }
  //   }
  // }

  // #features-list {
  //   .feature {
  //     flex: 0 0 33.3333%;
  //   }
  // }
}

@media (width > breakpoints.$lg) {
  // #features-list {
  //   .feature {
  //     flex: 0 0 25%;
  //   }
  // }
}
